











































import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import Field from '@/shared/classes/form/field'
import { setDefaultFieldValue } from '@/shared/helpers/components/form/preset-initial-values.helper'
import { FieldType } from '@/shared/configs/field-type.config'
import _ from 'lodash'
import ArrayFieldClass from '@/shared/classes/form/fields/array-field'

@Component({
  components: {
    FormField: () => import('@/components/form/FormField.vue')
  },
})
export default class ArrayField extends AbstractField {
  addItem(): void {
    const value: any = {}

    const field: ArrayFieldClass | any = this.field

    field.children.forEach((field: Field) => {
      value[field.key] = setDefaultFieldValue(field)
    })

    _.set(this.form.data, this.fullKey, [
      ..._.get(this.form.data, this.fullKey),
      value
    ])

    this.removeError()
  }

  removeItem(index: number) {
    _.set(this.form.data, this.fullKey, _.get(this.form.data, this.fullKey).filter((value: any, i: number) => i !== index))
  }

  getChild(child: Field): Field {
    return child
  }
}
